import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  Grid,
  makeStyles,
  Drawer, 
  Button,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Survey from './components/Survey';
import Box from '@material-ui/core/Box';
import { labels } from 'src/labels';
import label from 'src/utils/label';
import EditIcon from '@material-ui/icons/Edit';
import CaseModal from './components/CaseModal';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingTop: theme.spacing(3)
  },
  paper: {
    margin: theme.spacing(5, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  actions: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const HomeView = () => {
  const classes = useStyles();
  let { token } = useParams();
  let query = useQuery();
  let lang = query.get("l") ? query.get("l") : 'ar';
  let agentView = (query.get("agent") === 'true') ? true : false
  let agentUserId = query.get("agentUserId") ? query.get("agentUserId") : '-1'
  let agentRole = query.get("agentRole") ? query.get("agentRole") : 'AGENT_ROLE'
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  return (
    <Page
      title="Smart Survey"
      className={classes.root}
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <div className={classes.paper}>
          <Grid container>
            <Grid item xs={12} sm container>
              <Box
                display="flex"
                flexDirection="column"
                height="100%"
                width="100%"
                justifyContent="center"
              >
                <Survey lang={lang} token={token} ro={query.get("ro") ? query.get("ro") : false} pv={query.get("pv") ? query.get("pv") : false} agent={agentView} />
              </Box>
            </Grid>
          </Grid>
        </div>
        {agentView && <Drawer
          anchor="bottom"
          open={true}
          // eslint-disable-next-line react/jsx-sort-props
          PaperProps={{ elevation: 1 }}
          variant="persistent"
        >
          <div className={classes.actions}>
            <Button color='secondary' variant="contained" onClick={handleOpen}>
              <EditIcon fontSize="small" className={classes.buttonIcon} />
              {label(labels.get("add_new_action"))}
            </Button>
          </div>
        </Drawer>}
        <CaseModal agentUserId={agentUserId} agentRole={agentRole} token={token} open={modalOpen} handleClose={handleClose} />
      </Grid>
    </Page >
  );
};
export default HomeView;
