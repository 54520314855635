import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Box,
  Typography
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import { useParams } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import theme from 'src/theme';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    height: '4em'
  },
  listItem: {
    borderRadius: "0.25em",
    maxHeight: '2em',
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  label : {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius,
    lineHeight: '10px',
    fontSize: '10px',
    height: 32,
    minWidth: 20,
    whiteSpace: 'nowrap',
    backgroundColor: '#FFF',
    padding: theme.spacing(0.5, 1)
  }
}));


const TopBar = ({ languages, surveyId, lang, token, ro, pv, className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedLang, setSelectedLang] = React.useState(lang);
  let params = useParams();
  let query = useQuery();
  let agentView = (query.get("agent") === 'true') ? true : false
  let agentName = query.get("agentUserName") ? query.get("agentUserName") : 'Agent';
  
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, lang) => {
    setSelectedLang(lang);
    setAnchorEl(null);
    navigate('/r/' + (!!token ? token : params.token) + '?l=' + lang + (ro ? '&ro=' + ro : '') + (pv ? '&pv=' + pv : '') + (query.get("agent") ? '&agent=' + query.get("agent") : '') + (query.get("agentUserId") ? '&agentUserId=' + query.get("agentUserId") : '') +(query.get("agentUserName") ? '&agentUserName=' + query.get("agentUserName") : ''), { replace: true });

    window.location.reload();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar
      style={{ background: '#221bff' }}
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      {languages && languages.length > 0 &&
        < Toolbar className={classes.toolbar}>
          <Logo />
          <Box flexGrow={1} />
            {agentView && <Box p={2} display="flex" alignItems="center" justifyContent="center" flexDirection="row">
              <Typography
                style={{backgroundColor: theme.palette.primary.light, color: 'white'}} 
                className={classes.label}
                variant="overline"
              >
                <PersonIcon/>
                &nbsp; {agentName} 
              </Typography>
            </Box>}
            <Box>
            <List component="nav">
              <ListItem
                style={{backgroundColor: theme.palette.primary.light}}
                className={classes.listItem}
                button
                onClick={handleClickListItem}
              >
                <ListItemText secondary={<Typography style={{color: 'white'}} variant="body1" >{languages.filter(language => !language.code.includes(selectedLang)).map(obj => obj.label)[0]}</Typography>} />
              </ListItem>
            </List>
            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {languages.map((language, index) => (
                <MenuItem
                  key={index}
                  selected={language.code === selectedLang}
                  onClick={(event) => handleMenuItemClick(event, language.code)}
                >
                  {language.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      }
    </AppBar >
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
