import React from 'react';
import { Navigate } from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import NotFoundView from 'src/views/errors/NotFoundView';
import HomeView from 'src/views/home/HomeView';

const routes = [
  {
    path: 'r/:token',
    element: <MainLayout />,
    children: [
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <HomeView /> },
      { path: '*', element: <Navigate to="404" /> }
    ]
  },
  {
    path: 'health',
    element: <div dir='ltr'>OK</div>
  }
];

export default routes;
