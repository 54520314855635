import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import label from 'src/utils/label';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { green } from "@material-ui/core/colors";
import { labels } from 'src/labels';

const useStyles = makeStyles((theme) => ({
    root: {
        '.MuiAutocomplete-option': {
            display:'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
        },
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& label.Mui-focused': {
            color: green[600],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: green[400],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: green[400],
            },
            '&:hover fieldset': {
                borderColor: green[400],
            },
            '&.Mui-focused fieldset': {
                borderColor: green[600],
            },
        },
    }
}));

const Dropdown = ({ ro, isChecked, handleChange, questionId, answers }) => {
    const classes = useStyles();
    const [dropdownValue, setDropdownValue] = React.useState(null);
    const handleDropdownChange = (event, newValue) => {
        setDropdownValue(newValue);
        let v = !!newValue ? newValue.questionId + '_' + newValue.id : null;
        event.target.type = 'select'
        handleChange(event, v, questionId);
    };
    const getDropDownValue = answers.filter((answer) => {
        if(isChecked(questionId, answer.id)) {
            return answer;
        } else return null;
    })[0]

    useEffect(() => {
        if(getDropDownValue){
            setDropdownValue(getDropDownValue)
        } else setDropdownValue(null)
    }, [getDropDownValue]);
    return (
        <Autocomplete
            disableClearable
            id="combo-box-demo"
            className={classes.root}
            options={answers}
            getOptionLabel={option => !!option ? label(option.label) : ''}
            getOptionSelected={(option, value) => option.id === value.id}
            value={dropdownValue}
            onChange={handleDropdownChange}
            disabled={JSON.parse(ro)}
            renderInput={(params) => (
                <TextField
                {...params}
                fullWidth
                label={label(labels.get("choose_option"))}
                variant="outlined"
                inputProps={{
                    ...params.inputProps,
                    autoComplete: "disabled"
                    }}
                />
            )}
        />
    );
}

export default Dropdown;