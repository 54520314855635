import React, {useState, useEffect} from 'react';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { yellow, grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from "@material-ui/core";
import theme from 'src/theme';
import label from 'src/utils/label';
import StarIcon from '@material-ui/icons/Star';


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiToggleButton-root": {
            border: 0
        },
        '& .MuiToggleButton-root.Mui-selected': {
            color: yellow["800"],
            backgroundColor: 'white'
        },
        "& .MuiToggleButton-root:hover": {
            color: yellow["800"],
            backgroundColor: 'white'
        }
    },
    on: {
        color: yellow["800"]
    },
    off: {
        color: grey[500]
    }
}))

const Star = ({ ro, isChecked, handleChange, questionId, answers }) => {
    const classes = useStyles();
    const [value, setStarValue] = React.useState("");
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    let changeSize = localStorage.getItem('lang') === 'ar' && mobileDevice ? '14px' : '12px';
    let _answers = answers.sort((a, b) => (a.score < b.score) ? -1 : 1)
    const getStarRating = _answers.findIndex((answer) => isChecked(questionId, answer.id)) + 1;
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    useEffect(() => {
        if(getStarRating > 0){
            setRating(getStarRating);
            setHover(getStarRating);
        } else {
            setRating(0);
            setHover(0);
        }
    }, [getStarRating]);
    
    const handleStarChange = (event, newValue) => {
        if(!!newValue) {
            let answerId = parseInt(newValue.split('_')[1]);
            const index = _answers.findIndex((item => item.questionId === questionId && item.id === answerId));
            setRating(index+1);
        } else {
            setRating(0);
            setHover(0);
        }
        setStarValue(newValue);
        handleChange(event, newValue, questionId);
    };

    const getLabel = (value) => {
        let questionId = parseInt(value.split('_')[0]);
        let answerId = parseInt(value.split('_')[1]);
        const index = _answers.findIndex((item => item.questionId === questionId && item.id === answerId));
        return (index >= 0) ? label(_answers[index].label) : '';
    }

    const answersList = _answers.map((answer, index) => {
        return (
            <ToggleButton className={(index+1 <= (hover || rating)) || value > 1 ? classes.on : classes.off}
                disabled={JSON.parse(ro)} 
                selected={isChecked(questionId, answer.id)} 
                key={answer.id} 
                value={questionId + '_' + answer.id} 
                onMouseEnter={() => setHover(index+1)}
                onMouseLeave={() => setHover(rating)}
            >
                <StarIcon fontSize='default' />
            </ToggleButton>
        )
    });
    return (
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <ToggleButtonGroup size={mobileDevice ? 'small' : 'large'} className={classes.root} value={value} exclusive onChange={handleStarChange}>
                {answersList}
            </ToggleButtonGroup>
            {
                value !== null && value !== '' && (
                    <Box display="flex" p={2} alignItems="center" justifyContent="center">
                        <Typography variant="body1" style={{fontSize: changeSize}}> {getLabel(value)} </Typography>
                    </Box>
                )
            }
        </Box>
    );
}

export default Star;