import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import label from 'src/utils/label';
import Answers from './Answers';
import { useMediaQuery } from "@material-ui/core";
import theme from 'src/theme';

const Question = ({ ro, getComments, isChecked, handleChange, question, answers, questions, varMap }) => {
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    let changeSize = localStorage.getItem('lang') === 'ar' && mobileDevice ? '17px' : '14px'
    let answersFiltered = question.answers;
    let filterConfig = question.filterConfig;
    if (!!filterConfig) {
        let selectedAnswerIds = answers.filter(item => item.questionId === filterConfig.sourceQuestionId).map(s => s.answerId);
        let selectedOtherAnswer = answers.find(item => item.questionId === filterConfig.sourceQuestionId && !!item.comments && item.comments !== "");
        let selectedQuestion = questions.find(q => q.id === filterConfig.sourceQuestionId);
        let selectedAnswerLabels = selectedQuestion.answers.filter(item => selectedAnswerIds.includes(item.id)).map(item => item.label.en);
        answersFiltered = answersFiltered.filter(item => selectedAnswerLabels.includes(item.label.en));
        if (selectedOtherAnswer) {
            answersFiltered.push({ "id": selectedOtherAnswer.answerId, "questionId": question.id, "score": 0, "comments": false, "label": { "ar": selectedOtherAnswer.comments, "en": selectedOtherAnswer.comments } })
        }
    }
    const handleQuestionLabel = (val) => {
        var result = val;
        if (varMap) {
            varMap.forEach((value, key) => {
                result = result.replaceAll(`{{${key}}}`, value);
            });
        }
        return result;
    };
    return (
        <Box p={2}>
            <Typography variant="subtitle2" style={{fontSize: changeSize, whiteSpace: 'pre-line'}}>
                {handleQuestionLabel(label(question.label))}
            </Typography>
            {question.answers
                && question.answers.length > 0
                && <Box pt={3}><Answers ro={ro} isComments={question.comments} getComments={getComments} isChecked={isChecked} handleChange={handleChange} type={question.type} questionId={question.id} answers={answersFiltered} /></Box>}
        </Box>
    );
}
export default Question;