import React, { useEffect, useCallback, useState} from 'react';
import {
  Card,
  Grid,
  makeStyles,
  CardContent,
  TextField,
  Button,
  Modal,
  Box
} from '@material-ui/core';
import { Form, Formik } from "formik";
import { labels } from 'src/labels';
import label from 'src/utils/label';
import CustomizedSnackbar from 'src/components/CustomizedSnackbar';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0,
        '&:last-child': {
        paddingBottom: 0
        }
    },
    details: {
        padding: theme.spacing(1, 3)
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: theme.spacing(4, 3)
    },
    button: {
        margin: theme.spacing(1)
    },
    comments: {
    '& .MuiTextField-root': {
        margin: theme.spacing(1),
    },
    '.MuiSelect-select': {
        display:'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
}
}));

const CaseModal = ({ token, open, handleClose, caseAction, agentUserId, agentRole, ...props }) => {
    const classes = useStyles();
    const formData = {token: token, caseAction : '-1', assigneeUserId: '-1', caseComments: '', agentUserId: Number(agentUserId)}
    
    const [actionOptions, setActionOptions] = React.useState(null);
    const [agentOptions, setAgentOptions] = React.useState(null);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarSeverity, setSnackBarSeverity] = useState('success');
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const snackBarAutoHideDuration = 2000;

    const handleSnackBarClose = () => {
        setSnackBarOpen(false);
    };

    const fetchActions = useCallback(() => {
        axios.get(API_URL + "/api/voc/case-actions")
            .then(response => {
                if (response.data && Array.isArray(response.data)) {
                    setActionOptions(response.data)
                }
            }).catch(error => {
                console.log(JSON.stringify(error));
            });
    }, []);
    
      const fetchAgents  = useCallback(() => {
        axios.get(API_URL + "/api/voc/agents")
            .then(response => {
                if (response.data && Array.isArray(response.data)) {
                    setAgentOptions(response.data)
                }
            }).catch(error => {
                console.log(JSON.stringify(error));
            });
    }, []);

    useEffect(() => {
        fetchActions();
        fetchAgents();
    }, [fetchActions, fetchAgents])

    return (
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Card className={classes.modal} >
        <CardContent className={classes.content}>
            <div className={classes.details}>
            <Formik
                enableReinitialize={true}
                initialValues = {formData}
                onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
                    let data = {};
                    data.token = values.token;
                    data.agentUserId = values.agentUserId;
                    if(values.caseAction !== '-1' || values.caseComments.trim().length > 0 || values.assigneeUserId !== '-1') {
                        if(values.caseAction !== '-1') {
                            data.caseAction = values.caseAction
                        }
                        if(values.caseComments.trim().length > 0 ) {
                            data.caseComments = values.caseComments.trim()
                        }
                        if(values.assigneeUserId !== '-1'){
                            data.assigneeUserId = Number(values.assigneeUserId)
                        }
                    }
                    setSnackBarSeverity('info');
                    setSnackBarMessage('Loading. Please wait...')
                    setSnackBarOpen(true);
                    axios.post(API_URL + "/api/voc/case-actions", data)
                        .then(response => {
                            if (response.data) {
                                setSubmitting(false);
                                setSnackBarSeverity('success');
                                setSnackBarMessage('Successfully saved')
                                //setSnackBarOpen(false);
                                console.log("Successfully saved case actions")
                            }
                        }).catch(error => {
                            setSubmitting(false);
                            setSnackBarSeverity('error');
                            setSnackBarMessage('Failed to save!')
                            setSnackBarOpen(false);
                            console.log(JSON.stringify(error));
                        });
                }}
            >
                {({ values, touched, errors, status, handleChange, handleBlur, isValid, isSubmitting, dirty }) => (
                <Form noValidate autoComplete="off">
                    <Grid
                    alignItems="center"
                    container
                    spacing={4}
                    >
                    <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            label={label(labels.get("add_new_action"))}
                            name="caseAction"
                            color='secondary'
                            onChange={handleChange}
                            select
                            SelectProps={{ native: true }}
                            value={values.caseAction}
                            variant="outlined"
                        >
                            <option value="-1">{label(labels.get("select"))}</option>
                            {!!actionOptions && actionOptions.map((a, index) => {
                                console.log(agentRole)
                                if(agentRole && agentRole !== 'AGENT MANAGER' && a.code === 'CASE_UNASSIGNED') return '';
                                return (
                                <option
                                    key={index}
                                    value={a.code}
                                >
                                    {a.desc}
                                </option>)
                            })}
                        </TextField>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                        fullWidth
                        variant="outlined"
                        color='secondary'
                        label={label(labels.get("reassign"))}
                        name="assigneeUserId"
                        select
                        onChange={handleChange}
                        SelectProps={{ native: true }}
                        value={values.assigneeUserId}
                        >
                            <option value="-1">{label(labels.get("select"))}</option>
                            {!!agentOptions && agentOptions.map((a, index) => (
                            <option
                                key={index}
                                value={a.userId}
                            >
                                {a.name}
                            </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            id="outlined-multiline-static"
                            multiline fullWidth rows={2}
                            variant="outlined"
                            color='secondary'
                            label={label(labels.get("comments"))}
                            name="caseComments"
                            value={values.caseComments}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button
                            className={classes.button}
                            type="submit"
                            color='secondary'
                            variant="contained"
                            disabled={((values.caseComments.trim().length === 0 && values.assigneeUserId === '-1' && values.caseAction === '-1' ) || isSubmitting)}
                        >
                            {label(labels.get("save"))}
                        </Button>
                        <Button
                            className={classes.button}
                            type="submit"
                            color='secondary'   
                            variant="contained"
                            onClick={handleClose}
                            disabled={isSubmitting}
                        >
                            {label(labels.get("cancel"))}
                        </Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                            <CustomizedSnackbar
                                open={snackBarOpen}
                                handleClose={handleSnackBarClose}
                                severity={snackBarSeverity}
                                msg={snackBarMessage}
                                autoHideDuration={snackBarAutoHideDuration}
                            />
                        </Box>
                    </Grid>
                    </Grid>
                </Form>
                )
                }
                </Formik >
            </div>
        </CardContent>
        </Card>
        </Modal>
    );
};

export default CaseModal;