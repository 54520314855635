import React, { useEffect, useState, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import label from 'src/utils/label';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& label.Mui-focused': {
            color: green[600],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: green[400],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: green[400],
            },
            '&:hover fieldset': {
                borderColor: green[400],
            },
            '&.Mui-focused fieldset': {
                borderColor: green[600],
            },
        },
    }
}));

const TextArea = ({ ro, getComments, handleChange, questionId, answer, isChecked }) => {
    const classes = useStyles();
    const [value, setValue] = useState('');
    const handleOnChange = useCallback(e => {
        setValue(e.target.value);
        handleChange(e);
    }, [handleChange]);
    useEffect(() => {
        setValue(getComments(questionId, answer.id));
    }, [getComments, questionId, answer]);
    return (
        <FormControlLabel
            className={classes.root}
            control={<TextField
                fullWidth
                label={label(answer.label)}
                id={questionId + '_' + answer.id}
                multiline rows={4}
                variant="outlined"
            />}
            value={value}
            onChange={handleOnChange}
            disabled={JSON.parse(ro) || isChecked(questionId, 528) || isChecked(questionId, 393)}
        />
    )
}


export default TextArea;